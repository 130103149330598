import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import * as $ from 'jquery';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {

    public userInfo;
    public loggedIn;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private authService: AuthService,
        private firebaseAuth: AngularFireAuth,
        private toastCtrl: ToastController,
        private route: Router,
        private menuCtrl: MenuController
    ) {
        // this.initializeApp()
    }

    ngOnInit(){
        if (localStorage.getItem('loggedIn') !== 'true') {
            this.loggedIn = 'false';
            this.route.navigate(['tabs/login']);
        }else{
            this.loggedIn = 'true';
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let email = this.userInfo.email;
            this.authService.getUserSiteInfo(email, this.userInfo)

            this.userInfo = this.authService.getUserSiteInfo(this.userInfo.email, this.userInfo);
            localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        }
    }

    // initializeApp() {
    //     this.platform.ready().then(() => {
    //         this.statusBar.styleLightContent();
    //         this.splashScreen.hide();
    //     });
    // }

    logOut() {
        return this.firebaseAuth.auth.signOut()
        .then(() => {
          this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
          localStorage.removeItem('userInfo');
          localStorage.removeItem('loggedIn');

          this.showToast('Logged out!');
          this.router.navigate(['tabs/login']);
        });
      }

      async showToast(message) {  
        const toast = await this.toastCtrl.create({  
          message: message,   
          duration: 3000,
          cssClass: 'toastMessage',
          position: 'top'
        });  
        toast.present();  
      }

    close() {
        this.menuCtrl.close();
    }

    goToHome() {
        this.route.navigate(['tabs/home']);
    }

    goToProfile() {
        this.route.navigate(['tabs/profile']);
    }

    goToDirectory() {
        this.route.navigate(['tabs/directory']);
    }

    goToPolls() {
        this.route.navigate(['tabs/polls']);
    }

    goToSettings() {
        this.route.navigate(['tabs/settings']);
    }
}
